import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Checkbox, Col, DatePicker, Dropdown, Form, Image, Input, Menu, Modal, Radio, Row, Select} from "antd";
// import './header.module.less';
import headerCss from './valueTypeView.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";

let store = require('src/store');
let mobx = require('mobx');
let history;
let menu;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');

let data = mobx.observable({
    objData: {},//弹窗里面修改的值
    columns: [],//列的数据 类似table里面column的配置
});

function initData(props) {
    data.objData = props.dataSource;
    data.columns = props.columns;
}

//列表里面添加或者更新数据的对话框  props要包含 {
//  valueType={columnItem.valueType} //值的类型如下switch
//  initialValue={}//初始化值
//  valueEnum={columnItem.valueEnum}//枚举数据
//  onChange={value => {//数据变化时进行回调
//      data.dataSource[columnItem.dataIndex] = value;
//  }}/>
// }
let ValueTypeView = observer(props => {
    history = useHistory();
    data = props;
    useEffect(() => {
        initData(props);
    }, []);
    // if (commonUtils.isEmpty(props.dataSource)) return null;
    let inputType = null;//不同类型显示不同的输入框  input 单选时间，多选等
    switch (props.valueType) {
        case 'dateTime':
            inputType = (
                <DatePicker defaultValue={moment(props.initialValue)}
                            suffixIcon={null}
                            disabled={props.disabled}
                            onChange={date => {
                                props.onChange(date)
                            }}/>
            )
            break;
        case 'select':
            inputType = (
                <Select defaultValue={props.initialValue}
                        disabled={props.disabled} onChange={(value) => {
                    props.onChange(value)
                }}>
                    {props.valueEnum.map(item => {
                        let title = item;
                        if (typeof item === 'object') {
                            title = item.title;
                        }
                        return (
                            <Select.Option value={item}>
                                {title + ''}
                            </Select.Option>
                        )
                    })}
                </Select>
            )
            break;
        case 'radio':
            inputType = (
                <Radio.Group defaultValue={props.initialValue}
                             disabled={props.disabled} onChange={e => {
                    props.onChange(e.target.value)
                }}>
                    {
                        props.valueEnum.map(item => {
                            let title = item;
                            if (typeof item === 'object') {
                                title = item.title;
                            }
                            return (
                                <Radio value={item}>
                                    {title + ''}
                                </Radio>
                            )
                        })
                    }
                </Radio.Group>
            )
            break;
        case 'checkbox':
            inputType = (

                <Checkbox.Group
                    disabled={props.disabled} onChange={arr => {
                    const selectedOptions = props.valueEnum.filter(item =>
                        arr.includes(item.title)
                    );
                    props.onChange(selectedOptions);
                }}>
                    {
                        props.valueEnum.map(item => {
                            let title = item;
                            if (typeof item === 'object') {
                                title = item.title;
                            }
                            return (
                                <Checkbox value={title} checked={
                                    // 写一个方法判断是否选中，如果选中返回true，否则返回false，条件是props.initialValue里面是否有title，需要判断props.initialValue里面的title是不是对象
                                    props.initialValue?.some(item => {
                                            console.log('item', item);
                                            if (typeof item === 'object') {
                                                return item.title === title;
                                            }
                                            return item === title;
                                        }
                                    )}>{title + ''}</Checkbox>
                            )
                        })
                    }
                </Checkbox.Group>
            )
            break;
        default:
            inputType = (
                <Input defaultValue={props.initialValue}
                       disabled={props.disabled} onChange={e => {
                    props.onChange(e.target.value);
                }}/>
            )
            break
    }
    return inputType;
})

export default ValueTypeView;
