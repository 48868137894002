// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-advanced-search-form_1DUj2a {\n  display: flex;\n  flex-wrap: wrap;\n}\n.ant-advanced-search-form_1DUj2a .item_26FCC2 {\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/component/tableQueryFilter/tableQueryFilter.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;AACF;AAHA;EAKI,kBAAA;AACJ","sourcesContent":[".ant-advanced-search-form{\n  display: flex;\n  flex-wrap: wrap;\n\n  .item{\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ant-advanced-search-form": "ant-advanced-search-form_1DUj2a",
	"item": "item_26FCC2"
};
export default ___CSS_LOADER_EXPORT___;
