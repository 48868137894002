import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Collapse, DatePicker, Dropdown, Form, Image, Input, Menu, Modal, Row} from "antd";
// import './header.module.less';
import css from './tableQueryFilter.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";
import ValueTypeView from "../valueTypeView/valueTypeView";

const {Panel} = Collapse;
let store = require('src/store');
let mobx = require('mobx');
let history;
let menu;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');

let data = mobx.observable({
    queryFilter: {},//查询的条件
    columns: [],
});

function initData(props) {
    Object.assign(data, props)
    // data.columns = props.columns;
}

//表数据的查询条件
let TableQueryFilter = observer(props => {
    history = useHistory();
    useEffect(() => {
        initData(props);
    }, []);
    let queryItems = [];
    for (let columnItem of data.columns) {
        let formItem = (
            <Col span={4} key={columnItem.dataIndex} className={css.item}>
                <Form.Item
                    name={columnItem.title}
                    label={columnItem.title}
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: 'Input something!',
                    //     },
                    // ]}
                >
                    <ValueTypeView valueType={columnItem.valueType}
                                   initialValue={columnItem.initialValue}
                                   valueEnum={columnItem.valueEnum}
                                   onChange={value => {
                                       let key = columnItem.dataIndex;
                                       // 如果key是 数组的，就增加.连接
                                       console.log('key1', key)
                                       if (Array.isArray(key)) {
                                           key = key.join('.');
                                           console.log('key2', key)
                                       }
                                       if (value != '') {
                                           data.queryFilter[key] = value;
                                       } else delete data.queryFilter[key];
                                   }}/>
                </Form.Item>
            </Col>
        );
        queryItems.push(formItem);
    }
    return (
        <div>
            <Collapse defaultActiveKey={['0']} ghost>
                <Panel header="filter query" key="1">
                    <Form
                        name="advanced_search"
                        className={css[`ant-advanced-search-form`]}
                        onFinish={() => {
                            console.log(data.queryFilter);
                            props.onSure(data.queryFilter);
                        }}>
                        {queryItems}
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}>
                            <Button type="primary" htmlType="submit">
                                query
                            </Button>
                        </Form.Item>
                    </Form>
                </Panel>
            </Collapse>
        </div>
    )
})

export default TableQueryFilter;
