let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let system_config = {
    add_system_config(params){
        return axios.post('/admin/system_config/add_system_config', params);
    },
    del_system_config(params){
        return axios.post('/admin/system_config/del_system_config', params);
    },
    update_system_config(params){
        return axios.post('/admin/system_config/update_system_config', params);
    },
    get_system_configs(params){
        return axios.get('/admin/system_config/get_system_configs', {params});
    },
}

module.exports = system_config;