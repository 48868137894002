let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let register_event_info = {
    add_register_event_info(params){
        return axios.post('/admin/register_event_info/add_register_event_info', params);
    },
    del_register_event_info(params){
        return axios.post('/admin/register_event_info/del_register_event_info', params);
    },
    update_register_event_info(params){
        return axios.post('/admin/register_event_info/update_register_event_info', params);
    },
    get_register_event_infos(params){
        return axios.get('/admin/register_event_info/get_register_event_infos', {params});
    },
}

module.exports = register_event_info;
