let mobx = require('mobx');
// create State object
let registerState = mobx.observable({
    formData: {
        user_name: '',
        chooseLangs: [],
        chooseCerts: [],
        work_years: 0,//工作年限
    },
    chooseCerts: [],
    is_show_verify_ok: false,//是否显示验证成功的页面
    is_same_mail_address: false,//是否和邮寄地址一致
    choose_tz_now_time: 0,//选择的时区的当前时间
    phone_num: '',
    email_code: '',
    phone_code: '',
    phone_code_time: 0,//短信验证码有效时间
    email_code_time: 0,//短信验证码有效时间
    is_show_login_view: false,//是否显示填写验证码和登录的按钮
    login_type: '',
    timeZones: [ //目前只显示美国的几个时区
        // "Pacific/Honolulu",
        // "Atlantic Standard Time",
        // "Atlantic Daylight Time",
        // "East Standard Time, EST (UTC-5)",
        // "East Daylight Time, EDT (UTC-4)",
        // "Central Standard Time, CST (UTC-6)",
        // "Central Daylight Time, CDT (UTC-5)",
        // "Mountain Standard Time, MST (UTC-7)",
        // "Mountain Daylight Time, MDT (UTC-6)",
        // "Pacific Standard Time, PST (UTC-8)",
        // "Pacific Daylight Time, PDT (UTC-7)",
        //
        // "Alaska Standard Time, AKST (UTC-9)",
        // "Alaska Daylight Time, AKDT (UTC-8)",
        // "Hawaii Standard Time, HST (UTC-10)",
        // "Samoa Standard Time (UTC-11)",
        // "Chamorro Standard Time (UTC+10)",
        // "",
        // "",
        // "",
        "America/Puerto_Rico",
        "America/New_York",
        "America/Chicago",
        "America/Denver",
        "America/Los_Angeles",
        "America/Anchorage",
        "Pacific/Honolulu",
        "Pacific/Midway",
        "Pacific/Guam",

        //
        // "America/Anchorage",
        // "America/Santa_Isabel",
        // "America/Los_Angeles",
        // "America/Chihuahua",
        // "America/Phoenix",
        // "America/Denver",
        // "America/Guatemala",
        // "America/Chicago",
        // "America/Regina",
        // "America/Mexico_City",
        // "America/Bogota",
        // "America/Indiana/Indianapolis",
        // "America/New_York",
        // "America/Caracas",
        // "America/Halifax",
        // "America/Asuncion",
        // "America/La_Paz",
        // "America/Cuiaba",
        // "America/Santiago",
        // "America/St_Johns",
        // "America/Sao_Paulo",
        // "America/Godthab",
        // "America/Cayenne",
        // "America/Argentina/Buenos_Aires",
        // "America/Montevideo",
        // "Etc/GMT+2",
        // "Atlantic/Cape_Verde",
        // "Atlantic/Azores",
        // "Africa/Casablanca",
        // "Atlantic/Reykjavik",
        // "Europe/London",
        // "Etc/GMT",
        // "Europe/Berlin",
        // "Europe/Paris",
        // "Africa/Lagos",
        // "Europe/Budapest",
        // "Europe/Warsaw",
        // "Africa/Windhoek",
        // "Europe/Istanbul",
        // "Europe/Kiev",
        // "Africa/Cairo",
        // "Asia/Damascus",
        // "Asia/Amman",
        // "Africa/Johannesburg",
        // "Asia/Jerusalem",
        // "Asia/Beirut",
        // "Asia/Baghdad",
        // "Europe/Minsk",
        // "Asia/Riyadh",
        // "Africa/Nairobi",
        // "Asia/Tehran",
        // "Europe/Moscow",
        // "Asia/Tbilisi",
        // "Asia/Yerevan",
        // "Asia/Dubai",
        // "Asia/Baku",
        // "Indian/Mauritius",
        // "Asia/Kabul",
        // "Asia/Tashkent",
        // "Asia/Karachi",
        // "Asia/Colombo",
        // "Asia/Kolkata",
        // "Asia/Kathmandu",
        // "Asia/Almaty",
        // "Asia/Dhaka",
        // "Asia/Yekaterinburg",
        // "Asia/Yangon",
        // "Asia/Bangkok",
        // "Asia/Novosibirsk",
        // "Asia/Krasnoyarsk",
        // "Asia/Ulaanbaatar",
        // "Asia/Shanghai",
        // "Australia/Perth",
        // "Asia/Singapore",
        // "Asia/Taipei",
        // "Asia/Irkutsk",
        // "Asia/Seoul",
        // "Asia/Tokyo",
        // "Australia/Darwin",
        // "Australia/Adelaide",
        // "Australia/Hobart",
        // "Asia/Yakutsk",
        // "Australia/Brisbane",
        // "Pacific/Port_Moresby",
        // "Australia/Sydney",
        // "Asia/Vladivostok",
        // "Pacific/Guadalcanal",
        // "Etc/GMT-12",
        // "Pacific/Fiji",
        // "Asia/Magadan",
        // "Pacific/Auckland",
        // "Pacific/Tongatapu",
        // "Pacific/Apia"
    ],
});

// export default registerState;
module.exports = registerState;