import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Col, message, Modal, Space, Table, Tooltip } from 'antd';
import { useHistory } from "react-router-dom";
import css from './order.module.less';
import { useLocation } from "react-router";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    orders: [],
    query: {
        page: 1,
        pageSize: 10,
    },//查询条件
    add_order_data: {},//添加用户数据
    update_order_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
});

let columns;

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/order?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.order.get_orders(data.query);
    if (res.code != 0) return;
    data.orders = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    columns = [
        // {
        //     title: '_id',
        //     dataIndex: '_id',
        //     key: '_id',
        //     width: 150,
        //     ellipsis: true,
        // },

        {
            title: 'interpret_lang_user_need',
            dataIndex: ['interpret_lang_user_need', 'language_name'],
            key: 'interpret_lang_user_need',
            width: 120,
            disabled: true,
        },
        {
            title: 'ctime',
            dataIndex: 'ctime',
            key: 'ctime',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(ctime) {
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render(status) {
                //订单状态 0：已失效  1：预约中  2：已预约
                // 3：口译订单执行中  4口译师开始  5用户开始   6口译师结束  7用户结束
                // 8：订单执行完成  9订单暂停  10订单错误
                let status_text = '';
                switch (status) {
                    case 0:
                        status_text = 'failure';
                        break;
                    case 1:
                        status_text = 'booking';
                        break;
                    case 2:
                        status_text = 'booked';
                        break;
                    case 3:
                        status_text = 'order_active';
                        break;
                    case 4:
                        status_text = 'intp_start';
                        break;
                    case 5:
                        status_text = 'user_start';
                        break;
                    case 6:
                        status_text = 'intp_end';
                        break;
                    case 7:
                        status_text = 'user_end';
                        break;
                    case 8:
                        status_text = 'order_complete';
                        break;
                    case 9:
                        status_text = 'order_pause';
                        break;
                    case 10:
                        status_text = 'order_error';
                        break;
                }
                return status_text;
            }
        },
        {
            title: 'real_start_time',
            dataIndex: 'real_start_time',
            key: 'real_start_time',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'real_end_time',
            dataIndex: 'real_end_time',
            key: 'real_end_time',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'total_time',
            dataIndex: 'total_time',
            key: 'total_time',
            width: 80,
            disabled: true,
        },
        {
            title: 'admin_add_total_time',
            dataIndex: 'admin_add_total_time',
            key: 'admin_add_total_time',
            width: 80,
        },
        {
            title: 'operate_admin.email',
            dataIndex: ['operate_admin', 'email'],
            key: 'operate_admin.email',
            width: 120,
        },
        {
            title: 'user.email',
            dataIndex: ['user', 'email'],
            key: 'user.email',
            width: 120,
            disabled: true,
        },
        {
            title: 'user_id2',
            dataIndex: ['user', 'id2'],
            key: 'user.id2',
            width: 120,
            disabled: true,
        },
        {
            title: 'user_evaluate_star',
            dataIndex: 'user_evaluate_star',
            key: 'user_evaluate_star',
            width: 120,
            render: (text, record) => {
                if (!record.user_evaluate) return null;
                return (
                    <Tooltip title={
                        <div>
                            <div>rate: {record.user_evaluate.rate}</div>
                            <div>rate_effective: {record.user_evaluate.rate_effective}</div>
                            <div>rate_efficiency: {record.user_evaluate.rate_efficiency}</div>
                            <div>rate_voice_accent: {record.user_evaluate.rate_voice_accent}</div>
                            <div>rate_professionality: {record.user_evaluate.rate_professionality}</div>
                            <div>rate_vocabulary: {record.user_evaluate.rate_vocabulary}</div>
                            <div>evaluate_desc: {record.user_evaluate.evaluate_desc}</div>
                        </div>
                    }>
                        {text}
                    </Tooltip>
                );
            }
        },
        {
            title: 'user_unit_price',
            width: 120,
            render: (text, record) => {
                return Number(record.order_price ?? 0).div(record.total_time ?? 0).toFixed(2);
            }
        },
        {
            title: 'interpreter.email',
            dataIndex: ['interpreter', 'email'],
            key: 'interpreter.email',
            width: 120,
            disabled: true,
        },
        {
            title: 'interpreter_id2',
            dataIndex: ['interpreter', 'id2'],
            key: 'interpreter.id2',
            width: 120,
            disabled: true,
        },
        {
            title: 'interpreter_evaluate_star',
            dataIndex: 'interpreter_evaluate_star',
            key: 'interpreter_evaluate_star',
            width: 120,
            render: (text, record) => {
                if (!record.interpreter_evaluate) return null;
                return (
                    <Tooltip title={
                        <div>
                            <div>rate: {record.interpreter_evaluate.rate}</div>
                            <div>rate_effective: {record.interpreter_evaluate.rate_effective}</div>
                            <div>rate_efficiency: {record.interpreter_evaluate.rate_efficiency}</div>
                            <div>rate_voice_accent: {record.interpreter_evaluate.rate_voice_accent}</div>
                            <div>rate_professionality: {record.interpreter_evaluate.rate_professionality}</div>
                            <div>rate_vocabulary: {record.interpreter_evaluate.rate_vocabulary}</div>
                            <div>evaluate_desc: {record.interpreter_evaluate.evaluate_desc}</div>
                        </div>
                    }>
                        {text}
                    </Tooltip>
                );
            }

        },
        {
            title: 'interpreter_unit_price',
            width: 120,
            render: (text, record) => {
                return Number(record.interpreter_price ?? 0).div(record.total_time ?? 0).toFixed(2);
            }
        },
        // {
        //     title: 'admin_change_price',
        //     dataIndex: 'admin_change_price',
        //     key: 'admin_change_price',
        //     width: 120,
        // },
        {
            title: 'cert_name',
            dataIndex: 'certs',
            key: 'certs.cert_name',
            disabled: true,
            width: 120,
            render(arr) {
                return arr.map(item => {
                    return item.cert_name;   //使其返回数组中的一项
                }).join(",");   //因为数据中user_name可能是两个人所以用逗号分隔开
            }
        },
        // {
        //     title: 'contact.phone',
        //     dataIndex: ['contact', 'phone_num'],
        //     key: 'contact.phone',
        //     width: 120,
        // },
        {
            title: 'interpreter_price',
            dataIndex: 'interpreter_price',
            key: 'interpreter_price',
            width: 120,
            disabled: true,
        },
        // {
        //     title: 'intp_award_rate',
        //     dataIndex: 'intp_award_rate',
        //     key: 'intp_award_rate',
        //     width: 120,
        // },
        // {
        //     title: 'notify_user_status',
        //     dataIndex: 'notify_user_status',
        //     key: 'notify_user_status',
        //     width: 120,
        // },

        {
            title: 'no_find_and_notify_online_interpreters',
            dataIndex: 'no_find_and_notify_online_interpreters',
            key: 'no_find_and_notify_online_interpreters',
            width: 220,
            disabled: true,
            render: (no_find_and_notify_online_interpreters) => {
                if (!no_find_and_notify_online_interpreters) return null;

                // 生成内容字符串
                const content = no_find_and_notify_online_interpreters.map(interpreter => {
                    return interpreter.id2 + ', ' + interpreter.second_name + '\r\n';
                }).join('');

                // 返回带有Tooltip的内容
                return (
                    <Tooltip title={
                        no_find_and_notify_online_interpreters.map(interpreter => {
                            return (
                                <div
                                    style={{ whitespace: 'nowrap' }}>  {interpreter.id2},{interpreter.second_name}  </div>
                            )
                        })
                    }>
                        {/* 这里可以根据需要切割内容，以适应列的宽度 */}
                        {content.length > 60 ? content.slice(0, 60) + '...' : content}
                    </Tooltip>
                );
            }
        },
        // {
        //     title: 'online_interpreters',
        //     dataIndex: 'online_interpreters',
        //     key: 'online_interpreters',
        //     width: 220,
        //     disabled: true,
        //     render: (online_interpreters) => {
        //         if (!online_interpreters) return null;
        //
        //         // 生成内容字符串
        //         const content = online_interpreters.map(interpreter => {
        //             return interpreter.id2 + ', ' + interpreter.second_name + '\r\n';
        //         }).join('');
        //
        //         // 返回带有Tooltip的内容
        //         return (
        //             <Tooltip title={
        //                 online_interpreters.map(interpreter => {
        //                     return (
        //                         <div
        //                             style={{whitespace: 'nowrap'}}>  {interpreter.id2},{interpreter.second_name}  </div>
        //                     )
        //                 })
        //             }>
        //                 {/* 这里可以根据需要切割内容，以适应列的宽度 */}
        //                 {content.length > 60 ? content.slice(0, 60) + '...' : content}
        //             </Tooltip>
        //         );
        //     }
        // },
        {
            title: 'find_notify_distribute_interpreters',
            dataIndex: 'find_notify_distribute_interpreters',
            key: 'find_notify_distribute_interpreters',
            width: 220,
            disabled: true,
            render: (find_notify_distribute_interpreters, record) => {
                if (!find_notify_distribute_interpreters) return null;

                // 生成内容字符串
                const content = find_notify_distribute_interpreters.map(interpreter => {
                    return interpreter.id2 + ', ' + interpreter.second_name + '\r\n';
                }).join('');

                // 返回带有Tooltip的内容
                return (
                    <Tooltip title={
                        find_notify_distribute_interpreters.map(interpreter => {
                            let is_receive = false;
                            //从find_notify_distribute_interpreters_data里面找到对应的数据，然后获取is_receive的值
                            for (let item of record.find_notify_distribute_interpreters_data) {
                                if (item.interpreter == interpreter._id) {
                                    is_receive = item.is_receive;
                                    break;
                                }
                            }
                            return (
                                <div
                                    style={{ whitespace: 'nowrap' }}>  {interpreter.id2},{interpreter.second_name},{is_receive ? 'receive' : 'not receive'}  </div>
                            )
                        })
                    }>
                        {/* 这里可以根据需要切割内容，以适应列的宽度 */}
                        {content.length > 60 ? content.slice(0, 60) + '...' : content}
                    </Tooltip>
                );
            }
        },
        {
            title: 'decline_interpreter',
            dataIndex: 'decline_interpreter',
            key: 'decline_interpreter',
            width: 220,
            disabled: true,
            render: (decline_interpreter) => {
                if (!decline_interpreter) return null;

                // 生成内容字符串
                const content = decline_interpreter.map(interpreter => {
                    return interpreter.id2 + ', ' + interpreter.second_name + '\r\n';
                }).join('');

                // 返回带有Tooltip的内容
                return (
                    <Tooltip title={
                        decline_interpreter.map(interpreter => {
                            return (
                                <div
                                    style={{ whitespace: 'nowrap' }}>  {interpreter.id2},{interpreter.second_name}  </div>
                            )
                        })
                    }>
                        {/* 这里可以根据需要切割内容，以适应列的宽度 */}
                        {content.length > 60 ? content.slice(0, 60) + '...' : content}
                    </Tooltip>
                );
            }
        },
        {
            title: 'miss_order_interpreter',
            dataIndex: 'miss_order_interpreter',
            key: 'miss_order_interpreter',
            width: 220,
            disabled: true,
            render: (miss_order_interpreter) => {
                if (!miss_order_interpreter) return null;

                // 生成内容字符串
                const content = miss_order_interpreter.map(interpreter => {
                    return interpreter.id2 + ', ' + interpreter.second_name + '\r\n';
                }).join('');

                // 返回带有Tooltip的内容
                return (
                    <Tooltip title={
                        miss_order_interpreter.map(interpreter => {
                            return (
                                <div
                                    style={{ whitespace: 'nowrap' }}>  {interpreter.id2},{interpreter.second_name}  </div>
                            )
                        })
                    }>
                        {/* 这里可以根据需要切割内容，以适应列的宽度 */}
                        {content.length > 60 ? content.slice(0, 60) + '...' : content}
                    </Tooltip>
                );
            }
        },
        {
            title: 'order_price',
            dataIndex: 'order_price',
            key: 'order_price',
            width: 120,
            disabled: true,
        },
        {
            title: 'raw_order_price',
            dataIndex: 'raw_order_price',
            key: 'raw_order_price',
            width: 120,
            disabled: true,
        },
        // {
        //     title: 'subscribe_time',
        //     dataIndex: 'subscribe_time',
        //     key: 'subscribe_time',
        //     width: 120,
        //     render(ctime) {
        //         if (!ctime) return;
        //         return moment(ctime).format('yyyy-MM-DD HH:mm')
        //     }
        // },
        // {
        //     title: 'subscribe_time_length',
        //     dataIndex: 'subscribe_time_length',
        //     key: 'subscribe_time_length',
        //     width: 120,
        // },
        {
            title: 'support_service_type.type_name',
            dataIndex: ['support_service_type', 'type_name'],
            key: 'support_service_type.type_name',
            width: 120,
            disabled: true,
        },
        // {
        //     title: 'support_service_type.status',
        //     dataIndex: ['support_service_type', 'status'],
        //     key: 'support_service_type.status',
        //     width: 120,
        //     render(type) {
        //         if (type == 1) return 'real time';
        //         if (type == 2) return 'subscribe';
        //     }
        // },
        // {
        //     title: 'user_discount_rate',
        //     dataIndex: 'user_discount_rate',
        //     key: 'user_discount_rate',
        //     width: 120,
        // },
        // {
        //     title: 'special_intp_award_rate',
        //     dataIndex: 'special_intp_award_rate',
        //     key: 'special_intp_award_rate',
        //     width: 120,
        // },
        // {
        //     title: 'special_user_discount_rate',
        //     dataIndex: 'special_user_discount_rate',
        //     key: 'special_user_discount_rate',
        //     width: 120,
        // },
        // {
        //     title: 'pause_times',
        //     dataIndex: 'pause_times',
        //     key: 'pause_times',
        //     width: 120,
        //     render(arr) {
        //         return arr.map(item => {
        //             return item;   //使其返回数组中的一项
        //         }).join(",");   //因为数据中user_name可能是两个人所以用逗号分隔开
        //     }
        // },
        // {
        //     title: 'login_ip',
        //     dataIndex: 'login_ip',
        //     key: 'login_ip',
        // },
        // {
        //     title: 'mark',
        //     dataIndex: 'mark',
        //     key: 'mark',
        // },
        {
            title: 'is_use_agora_chat',
            dataIndex: 'is_use_agora_chat',
            key: 'is_use_agora_chat',
            width: 120,
            render(is_use_agora_chat) {
                return is_use_agora_chat ? 'Y' : 'N';
            }
        },
        {
            title: 'display',
            dataIndex: 'display',
            key: 'display',
            valueEnum: ['inline', 'none'],
            valueType: 'select',
            width: 120,
        },
        {
            title: 'obj_status_update_at.user_start_match_intp_at',
            dataIndex: ['obj_status_update_at', 'user_start_match_intp_at'],
            key: 'obj_status_update_at.user_start_match_intp_at',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'obj_status_update_at.user_end_match_intp_at',
            dataIndex: ['obj_status_update_at', 'user_end_match_intp_at'],
            key: 'obj_status_update_at.user_end_match_intp_at',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'obj_status_update_at.intp_start_order_at',
            dataIndex: ['obj_status_update_at', 'intp_start_order_at'],
            key: 'obj_status_update_at.intp_start_order_at',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'obj_status_update_at.user_start_order_at',
            dataIndex: ['obj_status_update_at', 'user_start_order_at'],
            key: 'obj_status_update_at.user_start_order_at',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'obj_status_update_at.intp_end_order_at',
            dataIndex: ['obj_status_update_at', 'intp_end_order_at'],
            key: 'obj_status_update_at.intp_end_order_at',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'obj_status_update_at.user_end_order_at',
            dataIndex: ['obj_status_update_at', 'user_end_order_at'],
            key: 'obj_status_update_at.user_end_order_at',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(ctime) {
                if (!ctime) return;
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'etime',
            dataIndex: 'etime',
            key: 'etime',
            valueType: 'dateTime',
            width: 160,
            disabled: true,
            render(etime) {
                return moment(etime).format('yyyy-MM-DD HH:mm')   //1是男，2是女
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 200,
            render: (text, record) => (
                <Space size="middle">
                    <Button type={'primary'} onClick={() => {
                        data.update_order_data = record;
                        data.is_show_update_dialog = true;
                    }}>edit</Button>
                    <Button type={'danger'} onClick={() => {
                        Modal.confirm({
                            content: 'are you sure delete this record',
                            onOk: function () {
                                delData(record)
                            },
                        })
                    }}>Delete</Button>
                </Space>
            ),
        },
    ];
    await getList(data.query.page, data.query.pageSize);
}

async function updateData(newData) {
    data.update_order_data = newData;
    if (data.update_order_data.admin_add_total_time) {
        data.update_order_data.operate_admin = store.persist.userInfo._id;
    }
    let res = await api.order.update_order(data.update_order_data);
    if (res.code != 0) return;
    message.success('update order success');
    data.is_show_update_dialog = false;
    getList();
}

async function addData(newData) {
    data.add_order_data = newData;
    let res = await api.order.add_order(data.add_order_data);
    if (res.code != 0) return;
    message.success('add order success');
    data.is_show_add_dialog = false;
    getList();
}

async function delData(data) {
    let res = await api.order.del_order({ _id: data._id });
    if (res.code != 0) return;
    message.success('del order success');
    getList();
}

// create observer
let Order = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    if (commonUtils.isEmpty(data.orders)) return <div/>;
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.orders)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }}>add new</Button>
            </Col>
            <Table
                rowKey="_id"
                columns={columns}
                dataSource={data.orders}
                scroll={{ x: 1000, y: 900 }}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true, showSizeChanger: true, defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_order_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            <AddUpdateDataDialog dataSource={data.update_order_data}
                                 columns={columns}
                                 is_show_dialog={data.is_show_update_dialog}
                                 onSure={(newData) => {
                                     updateData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_order_data = {};
                                 }}
            />
        </div>
    );
})
export default Order;
