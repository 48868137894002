let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');
let commonUtils = require('commonProject/utils/Common');

let admin = {
    login(params) {
        return axios.post('/admin/admin/login', params);
    },
    //获取用户数据 获取成功后自动保存到缓存里面
    async get_admin_info(params) {
        let res = await axios.get('/admin/admin/get_admin_info', {params});
        if (res.code == 0 && !commonUtils.isEmpty(res.data)) {
            store.persist.userInfo = res.data;
        }
        return res;
    },
    add_admin(params){
        return axios.post('/admin/admin/add_admin', params);
    },
    del_admin(params){
        return axios.post('/admin/admin/del_admin', params);
    },
    update_admin(params){
        return axios.post('/admin/admin/update_admin', params);
    },
    get_admins(params){
        return axios.get('/admin/admin/get_admins', {params});
    },
    verify_email_send_code (params){
        params.role_type='interpreter';
        return axios.post('/admin/admin/verify_email_send_code', params);
    },
}

module.exports = admin;
