import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    DatePicker,
    message, Modal,
    Row,
    Space,
    Table,
    Checkbox,
    Tag
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './user_advance_notice.module.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment-timezone');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    user_advance_notices: [],
    query: {
        page: 1,
        pageSize: 100,
    },//查询条件
    add_user_advance_notice_data: {},//添加用户数据
    update_user_advance_notice_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框,
});

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history,'/home_login/user_advance_notice?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.user_advance_notice.get_user_advance_notices(data.query);
    if (res.code != 0) return;
    data.user_advance_notices = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    await getList(data.query.page, data.query.pageSize);
}

async function updateUser(newData) {
    data.update_user_advance_notice_data = newData;
    let res = await api.user_advance_notice.update_user_advance_notice(data.update_user_advance_notice_data);
    if (res.code != 0) return;
    message.success('update user_advance_notice success');
    data.is_show_update_dialog = false;
    history.go(0);
}

async function addUser(newData) {
    data.add_user_advance_notice_data = newData;
    let res = await api.user_advance_notice.add_user_advance_notice(data.add_user_advance_notice_data);
    if (res.code != 0) return;
    message.success('add user_advance_notice success');
    data.is_show_add_dialog = false;
    getList();
}

async function delUser(data) {
    let res = await api.user_advance_notice.del_user_advance_notice({_id: data._id});
    if (res.code != 0) return;
    message.success('del user_advance_notice success');
    getList();
}

// create observer
let UserAdvanceNotice = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    const columns = [
        // {
        //     title: '_id',
        //     dataIndex: '_id',
        //     key: '_id',
        //     width: 150,
        //     ellipsis: true,
        // },
        /**
         * content
         * :
         * "dddssa"
         * ctime
         * :
         * "2023-07-15T04:17:15.216Z"
         * etime
         * :
         * "2023-07-15T04:17:15.216Z"
         * interpret_lang
         * :
         * "6113757a604f6638748e30ae"
         * interpreters
         * :
         * [{user_password: "", status: 2, work_status: 1, long_connect_status: "disconnected", login_ip: "",…},…]
         * 0
         * :
         * {user_password: "", status: 2, work_status: 1, long_connect_status: "disconnected", login_ip: "",…}
         * 1
         * :
         * {user_password: "", status: 2, work_status: 1, long_connect_status: "disconnected", login_ip: "",…}
         * 2
         * :
         * {user_password: "", status: 2, work_status: 0, long_connect_status: "connected", login_ip: "",…}
         * 3
         * :
         * {user_password: "", status: 2, work_status: 0, long_connect_status: "disconnected", login_ip: "",…}
         * 4
         * :
         * {user_password: "", status: 2, work_status: 0, long_connect_status: "disconnected", login_ip: "",…}
         * user
         * :
         * {user_name: "775311653@qq.com", user_password: "", status: 1, login_ip: "", mark: "",…}
         * all_money
         * :
         * 0
         * auto_pay_status
         * :
         * 1
         * company
         * :
         * "61e7aac0c6393b3ac49d017c"
         * ctime
         * :
         * "2022-09-24T07:08:45.927Z"
         * display
         * :
         * "inline"
         * email
         * :
         * "775311653@qq.com"
         * etime
         * :
         * "2023-07-05T02:32:27.189Z"
         * evaluate_rate
         * :
         * 5
         * first_name
         * :
         * "x"
         * id2
         * :
         * "11111008"
         * is_owner_self_team_admin
         * :
         * 0
         * is_summer_time
         * :
         * 0
         * login_ip
         * :
         * ""
         * mark
         * :
         * ""
         * max_auto_pay
         * :
         * 200
         * p_user
         * :
         * "632eac8cc746bc33fc9ba658"
         * second_name
         * :
         * "p72"
         * status
         * :
         * 1
         * team_name
         * :
         * "xpTeam"
         * token
         * :
         * "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzMmVhY2ZkYzc0NmJjMzNmYzliYTY1YSIsInVzZXJfbmFtZSI6Ijc3NTMxMTY1M0BxcS5jb20iLCJpYXQiOjE2ODg1MjQzNDYsImV4cCI6MTY5MTExNjM0Nn0.0i21aS7XBLVkCtlordm_HKp2oYjoMaqrmd72tzB4_pI"
         * try_out_status
         * :
         * 0
         * user_name
         * :
         * "775311653@qq.com"
         * user_password
         * :
         * ""
         * user_role_type
         * :
         * "employee"
         * __v
         * :
         * 0
         * _id
         * :
         * "632eacfdc746bc33fc9ba65a"
         */
        //需要有ctime,user.email,interpreters的id2和second_name 还有content
        {
            title: 'user',
            dataIndex: ['user', 'email'],
            key: 'user.email',
            ellipsis: true,
            width: 100,
        },
        {
            title: 'language',
            dataIndex: ['interpret_lang', 'language_name'],
            key: 'interpret_lang.language_name',
            ellipsis: true,
            width: 100,
        },
        {
            title: 'user time zone',
            dataIndex: ['user', 'time_zone'],
            key: 'user.time_zone',
            ellipsis: true,
            width: 180,
        },
        {
            title: 'content',
            dataIndex: 'content',
            key: 'content',
            width: 300,
            render(content, record) {
                return !!record.corrected_message ? <><Tag color="green">Corrected</Tag>{record.corrected_message}</> : content
            }
        },
        {
            title: "corrected stime",
            dataIndex: "corrected_start_time",
            key: "corrected_start_time",
            width: 160,
            render(ctime, record) {
                return !!ctime ? moment(ctime).tz(record.user.time_zone).format('yyyy-MM-DD HH:mm') : ""
            }
        },
        {
            title: "minutes",
            dataIndex: "corrected_duration_length",
            key: "corrected_duration_length",
            width: 100,
        },
        {
            title: 'canceled',
            dataIndex: 'corrected_is_canceled',
            key: 'corrected_is_canceled',
            width: 100,
            render(canceled, record) {
                return canceled ? <Tag color="red">Yes</Tag> : <></>
            }
        },
        {
            title: 'interpreters',
            dataIndex: 'interpreters',
            key: 'interpreters.id2',
            render: (text, record) => {
                let result = '';
                if (record.interpreters) {
                    record.interpreters.forEach((item, index) => {
                        result += item.id2+ ',\r';
                    })
                }
                return result;
            },
            width: 300,
        },
        {
            title: 'ctime',
            dataIndex: 'ctime',
            key: 'ctime',
            width: 160,
            render(ctime) {
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 180,
            render: (text, record) => (
                <Space size="middle">
                    <Button type={'primary'} onClick={() => {
                        data.update_user_advance_notice_data = record;
                        updateForm(data.update_user_advance_notice_data);
                        data.is_show_update_dialog = true;
                    }}>Edit</Button>
                    <Button type={'danger'} onClick={() => {
                        Modal.confirm({
                            content: 'are you sure delete this record',
                            onOk: function () {
                                delUser(record)
                            },
                        })
                    }}>Delete</Button>
                </Space>
            ),
        },
    ];

    const [form] = Form.useForm();

    const onFinishForm = values => {
        const tzDate = moment.tz(values.corrected_start_time.format("YYYY-MM-DD HH:mm:ss"), data.update_user_advance_notice_data.user?.time_zone).format();
        data.update_user_advance_notice_data.corrected_start_time = tzDate;
        data.update_user_advance_notice_data.corrected_duration_length = values.corrected_duration_length;
        data.update_user_advance_notice_data.corrected_message = values.corrected_message;
        data.update_user_advance_notice_data.corrected_is_canceled = values.is_canceled;
        updateUser(data.update_user_advance_notice_data);
    };

    const updateForm = (data) => {
        form.setFieldsValue({
            corrected_start_time: data.corrected_start_time ? 
                moment.tz(data.corrected_start_time, data.user?.time_zone) : 
                undefined,
            corrected_duration_length: data.corrected_duration_length,
            corrected_message: data.corrected_message,
            is_canceled: data.corrected_is_canceled ?? false,
        })
    };

    const resetForm = () => {
        form.resetFields();
    }

    const { TextArea } = Input;
 
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.user_advance_notices)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }} style={{marginBottom: "10px"}}>add new</Button>
            </Col>
            <Table
                // style={{display:'inline'}}
                rowKey="_id"
                columns={columns}
                dataSource={data.user_advance_notices}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addUser(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_user_advance_notice_data = {};
                                 }}
            />
            {/*modify corrected_start_time and corrected_duration_length*/}
            <Modal title="Correct Data" visible={data.is_show_update_dialog ?? true} onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_user_advance_notice_data = {};
                                 }} footer={null}>
                {/* show user time zone */}
                <h4 style={{fontWeight: "bold"}}>Time Zone:</h4>
                <p>{data.update_user_advance_notice_data.user?.time_zone}</p>
                {/* show user content */}
                <h4 style={{fontWeight: "bold"}}>Content:</h4>
                <p>{data.update_user_advance_notice_data.content}</p>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    onFinish={onFinishForm}
                >
                    {/**Use Antd Date Picker */}
                    <Form.Item
                        label="Started Time"
                        name="corrected_start_time"
                        rules={[{required: true, message: 'Please input corrected_start_time!'}]}
                    >
                        <DatePicker showTime={{ format: 'HH:mm' }} />
                    </Form.Item>
                    <Form.Item
                        label="Duration"
                        name="corrected_duration_length"
                        rules={[
                            {
                                required: true,
                                message: 'Please input corrected_duration_length!',
                            },
                        ]}
                    >
                        <Input type="number" placeholder="If the duration is uncertain, fill in 0"/>
                    </Form.Item>
                    <Form.Item
                        label="Message"
                        name="corrected_message"
                        rules={[
                            {
                                required: true,
                                message: 'Please input corrected_message!',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        label="Is Canceled"
                        name="is_canceled"
                        valuePropName="checked"
                    >
                        <Checkbox>
                            If this notice has been canceled.</Checkbox>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
})
export default UserAdvanceNotice;
