import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    DatePicker,
    message, Modal,
    Row,
    Space,
    Table,
    Checkbox,
    Tag
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './page_ip.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment-timezone');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    page_ips: [],
    query: {
        page: 1,
        pageSize: 100,
    },//查询条件
    add_page_ip_data: {},//添加用户数据
    update_page_ip_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框,
});

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/page_ip?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.page_ip.get_page_ips(data.query);
    if (res.code != 0) return;
    data.page_ips = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    await getList(data.query.page, data.query.pageSize);
}

async function updateUser(newData) {
    data.update_page_ip_data = newData;
    let res = await api.page_ip.update_page_ip(data.update_page_ip_data);
    if (res.code != 0) return;
    message.success('update page_ip success');
    data.is_show_update_dialog = false;
    history.go(0);
}

async function addUser(newData) {
    data.add_page_ip_data = newData;
    let res = await api.page_ip.add_page_ip(data.add_page_ip_data);
    if (res.code != 0) return;
    message.success('add page_ip success');
    data.is_show_add_dialog = false;
    getList();
}

async function delUser(data) {
    let res = await api.page_ip.del_page_ip({_id: data._id});
    if (res.code != 0) return;
    message.success('del page_ip success');
    getList();
}

// create observer
let PageIp = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    const columns = [
        //account_data: {
        //         type: Schema.Types.Mixed,
        //     },
        //     ip: {
        //         type: String,
        //     },
        //     page: {
        //         type: String,
        //         required: true,
        //     }
        {
            title: 'account_data',
            dataIndex: 'account_data',
            key: 'account_data',
            width: 200,
            render(account_data) {
                return JSON.stringify(account_data);
            }
        },
        {
            title: 'ip',
            dataIndex: 'ip',
            key: 'ip',
            width: 160,
        },
        {
            title: 'page',
            dataIndex: 'page',
            key: 'page',
            width: 160,
        },
        {
            title: 'timezone',
            dataIndex: 'timezone',
            key: 'timezone',
            width: 160,
        },
        {
            title: 'ctime',
            dataIndex: 'ctime',
            key: 'ctime',
            width: 160,
            render(ctime) {
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 180,
            render: (text, record) => (
                <Space size="middle">
                    <Button type={'primary'} onClick={() => {
                        data.update_page_ip_data = record;
                        updateForm(data.update_page_ip_data);
                        data.is_show_update_dialog = true;
                    }}>Edit</Button>
                    <Button type={'danger'} onClick={() => {
                        Modal.confirm({
                            content: 'are you sure delete this record',
                            onOk: function () {
                                delUser(record)
                            },
                        })
                    }}>Delete</Button>
                </Space>
            ),
        },
    ];

    const [form] = Form.useForm();

    const onFinishForm = values => {
        const tzDate = moment.tz(values.corrected_start_time.format("YYYY-MM-DD HH:mm:ss"), data.update_page_ip_data.user?.time_zone).format();
        data.update_page_ip_data.corrected_start_time = tzDate;
        data.update_page_ip_data.corrected_duration_length = values.corrected_duration_length;
        data.update_page_ip_data.corrected_message = values.corrected_message;
        data.update_page_ip_data.corrected_is_canceled = values.is_canceled;
        updateUser(data.update_page_ip_data);
    };

    const updateForm = (data) => {
        form.setFieldsValue({
            corrected_start_time: data.corrected_start_time ?
                moment.tz(data.corrected_start_time, data.user?.time_zone) :
                undefined,
            corrected_duration_length: data.corrected_duration_length,
            corrected_message: data.corrected_message,
            is_canceled: data.corrected_is_canceled ?? false,
        })
    };

    const resetForm = () => {
        form.resetFields();
    }

    const {TextArea} = Input;

    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  if (!Number.isInteger(query.page)) {
                                      query.user_page = query.page;
                                      delete query.page;
                                  } else delete query.user_page;
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.page_ips)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }} style={{marginBottom: "10px"}}>add new</Button>
            </Col>
            <Table
                // style={{display:'inline'}}
                rowKey="_id"
                columns={columns}
                dataSource={data.page_ips}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addUser(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_page_ip_data = {};
                                 }}
            />
            {/*modify corrected_start_time and corrected_duration_length*/}
            <Modal title="Correct Data" visible={data.is_show_update_dialog ?? true} onCancel={() => {
                data.is_show_update_dialog = false;
                data.update_page_ip_data = {};
            }} footer={null}>
                {/* show user time zone */}
                <h4 style={{fontWeight: "bold"}}>Time Zone:</h4>
                <p>{data.update_page_ip_data.user?.time_zone}</p>
                {/* show user content */}
                <h4 style={{fontWeight: "bold"}}>Content:</h4>
                <p>{data.update_page_ip_data.content}</p>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    onFinish={onFinishForm}
                >
                    {/**Use Antd Date Picker */}
                    <Form.Item
                        label="Started Time"
                        name="corrected_start_time"
                        rules={[{required: true, message: 'Please input corrected_start_time!'}]}
                    >
                        <DatePicker showTime={{format: 'HH:mm'}}/>
                    </Form.Item>
                    <Form.Item
                        label="Duration"
                        name="corrected_duration_length"
                        rules={[
                            {
                                required: true,
                                message: 'Please input corrected_duration_length!',
                            },
                        ]}
                    >
                        <Input type="number" placeholder="If the duration is uncertain, fill in 0"/>
                    </Form.Item>
                    <Form.Item
                        label="Message"
                        name="corrected_message"
                        rules={[
                            {
                                required: true,
                                message: 'Please input corrected_message!',
                            },
                        ]}
                    >
                        <TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item
                        label="Is Canceled"
                        name="is_canceled"
                        valuePropName="checked"
                    >
                        <Checkbox>
                            If this notice has been canceled.</Checkbox>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
})
export default PageIp;
