let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let order = {
    add_order(params){
        return axios.post('/admin/order/add_order', params);
    },
    del_order(params){
        return axios.post('/admin/order/del_order', params);
    },
    update_order(params){
        return axios.post('/admin/order/update_order', params);
    },
    get_orders(params){
        return axios.get('/admin/order/get_orders', {params});
    },
}

module.exports = order;