import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, Divider, message, Image, Input, Row} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './home.module.less';
import CountDown from "ant-design-pro/lib/CountDown";
import {useLocation} from "react-router";

let mobx = require('mobx');
let store = require('../../store');
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
// create State object
let data = mobx.observable({
    timer: 0,
    names: ['', ''],
    email: '',
    phone_num: '',
    email_code: '',
    phone_code: '',
    phone_code_time: 0,//短信验证码有效时间
    email_code_time: 0,//短信验证码有效时间
    is_show_login_view: false,//是否显示填写验证码和登录的按钮
    login_type: '',
});


//发送手机验证
async function verify_email_send_code() {
    let email = data.email;
    if (commonUtils.isEmpty(email)) return message.error('email is empty');
    message.info('clicked send');
    let res = await api.admin.verify_email_send_code({email: email, is_verify_new: false});
    if (res.code != 0) return;
    message.success('send code success');
    data.phone_code_time = new Date().getTime() + commonUtils.config.valid_code_time;
    data.is_show_login_view = true;
}


/**
 * 登录
 * @param type 登录方式  email邮箱登录  phone 手机号登录
 * @returns {MessageType}
 */
async function submitLogin(type = 'email') {
    let params;
    if (type == 'email') {
        params = {
            email: data.email,
            email_code: data.email_code,
        };
    } else if (type == 'phone') {
        params = {
            phone_num: data.phone_num,
            phone_code: data.phone_code,
        };
    } else if (type == 'landline') {
        params = {
            landline: data.landline,
            landline_code: data.landline_code,
        };
    }

    if (!params.email_code && !params.phone_code && !params.landline_code) return message.error('you entered a wrong passcode');

    let res = await api.admin.login(params);
    if (res.code != 0) return;
    store.persist.token = res.data.token;
    api.admin.get_admin_info();
    history.replace('/home_login');
}

// create observer
let Home = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
    }, []);

    return (
        !data.is_show_login_view ? (
            <Col className={css.containBox} align={'middle'}>
                <p className={css.headTitleBox + ' darkBlue'}>Admin Log In</p>
                <div className={css.loginBox}>
                    <span className={css.desc}>using email:</span>
                    <Input className={css.inputItem} placeholder='Your Email' defaultValue={data.email} onChange={(e) => {
                        data.email = e.target.value
                    }}/>
                    <Col align={'middle'}>
                        <Button className={css.btn} onClick={() => {
                            data.login_type = 'email';
                            verify_email_send_code();
                        }}>Send Code</Button>
                    </Col>
                </div>
            </Col>
        ) : (
            <Col className={css.containBox}>
                <p className={css.headTitleBox + ' darkBlue'}>Admin Log In</p>
                <div className={css.loginBox} align='middle'>
                    <div>
                        {data.login_type == 'phone' ? (
                            <div className={css.showInputCodeBox}>
                                <span className={css.desc}>using cell phone:</span><span
                                className={css.hint + 'hintGray'}>(We will text you a code).</span>
                                <p style={{display: 'none'}}>{data.phone_num}</p>
                                <Input placeholder='US 10-digit cell phone number' defaultValue={data.phone_num}
                                       onChange={(e) => {
                                           data.phone_num = e.target.value
                                       }}/>
                            </div>
                        ) : (
                            data.login_type == 'landline' ? (
                                <div className={css.showInputCodeBox}>
                                    <span className={css.desc}>using landline:</span><span
                                    className={css.hint + 'hintGray'}>(We will text you a code).</span>
                                    <p style={{display: 'none'}}>{data.landline}</p>
                                    <Input placeholder='US 10-digit cell phone number' defaultValue={data.landline}
                                           onChange={(e) => {
                                               data.landline = e.target.value
                                           }}/>
                                </div>
                            ) : (
                                <div className={css.showInputCodeBox}>
                                    <span className={css.desc}>using email:</span><span className={'hintGray'}>(We will email you a pass code).</span>
                                    <Input className={css.inputItem} placeholder='Your Email' defaultValue={data.email} onChange={(e) => {
                                        data.email = e.target.value
                                    }}/>
                                </div>
                            )
                        )}
                    </div>
                    <div className={css.phoneCodeBox}>
                        <p className={css.loginDesc + ' darkBlue'}>Enter you code:</p>
                        {
                            data.login_type == 'phone' ? (
                                <Input placeholder='phone code'
                                       className={css.phoneCode}
                                       defaultValue={data.phone_code}
                                       onChange={(e) => {
                                           data.phone_code = e.target.value
                                       }}/>
                            ) : (
                                data.login_type == 'landline' ? (
                                    <Input placeholder='landline code'
                                           className={css.phoneCode}
                                           defaultValue={data.landline_code}
                                           onChange={(e) => {
                                               data.landline_code = e.target.value
                                           }}/>
                                ) : (
                                    <Input placeholder='email code'
                                           className={css.phoneCode}
                                           defaultValue={data.email_code}
                                           onChange={(e) => {
                                               data.email_code = e.target.value
                                           }}/>
                                )
                            )
                        }
                        {data.phone_code_time == 0 ?
                            // <Button onClick={verify_phone_send_code}>send code</Button>
                            <span/>
                            : <CountDown
                                style={{color: 'red'}}
                                format={time => {
                                    return moment(time).format('mm:ss');
                                }}
                                target={data.phone_code_time}
                                onEnd={() => {
                                    data.phone_code_time = 0;
                                }}/>}
                    </div>
                    <Button
                        onClick={() => {
                            submitLogin(data.login_type)
                        }}
                        type='primary'
                        className={css.btn}>Log In</Button>
                    <div className={css.notGetCodeBox}>(Did not get the code?
                        <span className={css.reSend} onClick={() => {
                            if (data.email) {
                                verify_email_send_code();
                            }
                        }}>Click Resend</span>)
                    </div>
                </div>
            </Col>
        )

    );
})
export default Home;
