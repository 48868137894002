import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    message, Modal,
    Row,
    Space,
    Table
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './interpreter_work_status_statistics.module.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    interpreter_work_status_statisticss: [],
    query: {
        page: 1,
        pageSize: 100,
    },//查询条件
    add_interpreter_work_status_statistics_data: {},//添加用户数据
    update_interpreter_work_status_statistics_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
});

let columns = [
    {
        title: '_id',
        dataIndex: '_id',
        key: '_id',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'interpret_lang',
        dataIndex: ['interpret_lang', 'language_name'],
        key: 'interpret_lang',
        width: 180,
        valueEnum: store.persist.interpret_langs,
        valueType: 'checkbox',
    },
    {
        title: 'available_count',
        dataIndex: 'available_intps',
        key: 'available_count',
        width: 150,
        render(available_intps) {
            return available_intps.length;
        }
    },
    {
        title: 'working_count',
        dataIndex: 'working_intps',
        key: 'working_count',
        width: 150,
        render(working_intps) {
            return working_intps.length;
        }
    },
    {
        title: 'ctime',
        dataIndex: 'ctime',
        key: 'ctime',
        valueType: 'dateTime',
        width: 160,
        render(ctime) {
            return moment(ctime).format('yyyy-MM-DD HH:mm')
        }
    },
    {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 200,
        render: (text, record) => (
            <Space size="middle">
                <Button type={'primary'} onClick={() => {
                    data.update_interpreter_work_status_statistics_data = record;
                    data.is_show_update_dialog = true;
                }}>edit</Button>
                <Button type={'danger'} onClick={() => {
                    Modal.confirm({
                        content: 'are you sure delete this record',
                        onOk: function () {
                            delInterpreterWorkStatusStatistics(record)
                        },
                    })
                }}>Delete</Button>
            </Space>
        ),
    },
]

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/interpreter_work_status_statistics?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.interpreter_work_status_statistics.get_interpreter_work_status_statisticss(data.query);
    if (res.code != 0) return;
    data.interpreter_work_status_statisticss = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    await getList(data.query.page, data.query.pageSize);
    // await getInterpreterLanguages();
}

async function updateInterpreterWorkStatusStatistics(newData) {
    data.update_interpreter_work_status_statistics_data = newData;
    let res = await api.interpreter_work_status_statistics.update_interpreter_work_status_statistics(data.update_interpreter_work_status_statistics_data);
    if (res.code != 0) return;
    message.success('update interpreter_work_status_statistics success');
    data.is_show_update_dialog = false;
    history.go(0);
}

async function addInterpreterWorkStatusStatistics(newData) {
    data.add_interpreter_work_status_statistics_data = newData;
    let res = await api.interpreter_work_status_statistics.add_interpreter_work_status_statistics(data.add_interpreter_work_status_statistics_data);
    if (res.code != 0) return;
    message.success('add interpreter_work_status_statistics success');
    data.is_show_add_dialog = false;
    getList();
}

async function delInterpreterWorkStatusStatistics(data) {
    let res = await api.interpreter_work_status_statistics.del_interpreter_work_status_statistics({_id: data._id});
    if (res.code != 0) return;
    message.success('del interpreter_work_status_statistics success');
    getList();
}

async function getInterpreterLanguages() {
    let res = await api.interpret_lang.get_interpret_langs({pageSize: 1000});
    if (res.code != 0) return;
    let arr = res.data.item;
    for (let item of arr) {
        item.title = item.language_name;
    }
    store.persist.interpret_langs = arr;
}

// create observer
let InterpreterWorkStatusStatistics = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    if (commonUtils.isEmpty(data.interpreter_work_status_statisticss)) return <div/>;
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.interpreter_work_status_statisticss)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }}>add new</Button>
            </Col>
            <Table
                // style={{display:'inline'}}
                rowKey="_id"
                columns={columns}
                dataSource={data.interpreter_work_status_statisticss}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addInterpreterWorkStatusStatistics(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_interpreter_work_status_statistics_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            <AddUpdateDataDialog dataSource={data.update_interpreter_work_status_statistics_data}
                                 columns={columns}
                                 is_show_dialog={data.is_show_update_dialog}
                                 onSure={(newData) => {
                                     updateInterpreterWorkStatusStatistics(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_interpreter_work_status_statistics_data = {};
                                 }}
            />
        </div>
    );
})
export default InterpreterWorkStatusStatistics;
