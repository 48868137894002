let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let user = {
//add_register_event_info
    async add_register_event_info(params) {
        return await axios.post('/webapp/register_event_info/add_register_event_info', params);
    }
}

module.exports = user;
