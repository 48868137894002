import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {Button, Col, DatePicker, Dropdown, Form, Image, Input, Menu, Modal, Row} from "antd";
// import './header.module.less';
import headerCss from './addUpdateDataDialog.module.less'
import menuSvg from 'src/assets/img/header/menu.svg';
import {useHistory} from "react-router-dom";
import ValueTypeView from "../valueTypeView/valueTypeView";

let store = require('src/store');
let mobx = require('mobx');
let history;
let menu;
let commonUtils = require('commonProject/utils/Common');
let moment = require('moment');

let data = mobx.observable({
    dataSource: {},//弹窗里面修改的值
    columns: [],//列的数据 类似table里面column的配置
});

function initData(props) {
    data = props;
    data.dataSource = {...props.dataSource};
    data.columns = props.columns;
}

function getFormView(props) {
    if (commonUtils.isEmpty(data.columns)) return null;
    if (!props.dataSource) props.dataSource = {};
    initData(props)
    let formItems = [];
    for (let columnItem of data.columns) {
        if (commonUtils.isEmpty(columnItem.dataIndex)) continue;
        let initValue;
        if (props.dataSource) {
            initValue = props.dataSource[columnItem.dataIndex] ?? columnItem.initialValue;
        } else initValue = columnItem.initialValue;
        let inputType;//不同类型显示不同的输入框  input 单选时间，多选等
        inputType = <ValueTypeView valueType={columnItem.valueType}
                                   initialValue={initValue}
                                   valueEnum={columnItem.valueEnum}
                                   disabled={columnItem.disabled}
                                   onChange={value => {
                                       data.dataSource[columnItem.dataIndex] = value;
                                   }}/>
        let formItem = (
            <Form.Item
                label={columnItem.dataIndex}
                name={columnItem.dataIndex}
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please input!',
                //     },
                // ]}
            >
                {inputType}
            </Form.Item>
        )
        formItems.push(formItem);
    }
    let formView = (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={() => {
                props.onSure(data.dataSource);
            }}>
            {formItems}
            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
    return formView;
}

//列表里面添加或者更新数据的对话框  props要包含 {
//     dataSource: {},//对象内容
//     columns: [],//列的数据 类似table里面column的配置
//     is_show_dialog: false,
//     onSure:function, //成功的回调
//     oncancel:function,//失败的回调
// }
let AddUpdateDataDialog = observer(props => {
    history = useHistory();
    useEffect(() => {
        initData(props);
    }, []);
    // if (commonUtils.isEmpty(props.dataSource)) return null;
    return (
        // <div>sss</div>
        <Modal title="Basic Modal" visible={props.is_show_dialog ?? true} onCancel={props.onCancel} footer={null}>
            {getFormView(props)}
        </Modal>
    )
})

export default AddUpdateDataDialog;
