let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let cash_order = {
    add_cash_order(params){
        return axios.post('/admin/cash_order/add_cash_order', params);
    },
    del_cash_order(params){
        return axios.post('/admin/cash_order/del_cash_order', params);
    },
    update_cash_order(params){
        return axios.post('/admin/cash_order/update_cash_order', params);
    },
    get_cash_orders(params){
        return axios.get('/admin/cash_order/get_cash_orders', {params});
    },
}

module.exports = cash_order;