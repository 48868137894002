// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerContainBox1_3tLxfI .containBox1_vXxT5A {\n  font-size: 20px;\n}\n.headerContainBox1_3tLxfI .flexGrow1_22AMIL {\n  flex-grow: 1;\n}\n.headerContainBox1_3tLxfI .menuIcon_c2MIqF {\n  width: 3vw;\n  height: 3vw;\n}\n", "",{"version":3,"sources":["webpack://src/component/AddUpdateDataDialog/addUpdateDataDialog.module.less"],"names":[],"mappings":"AAAA;EAGI,eAAA;AADJ;AAFA;EAQI,YAAA;AAHJ;AALA;EAYI,UAAA;EACA,WAAA;AAJJ","sourcesContent":[".headerContainBox1 {\n\n  .containBox1{\n    font-size: 20px;\n    //background-color: green;\n  }\n\n  .flexGrow1 {\n    flex-grow: 1;\n  }\n\n  .menuIcon{\n    width: 3vw;\n    height: 3vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainBox1": "headerContainBox1_3tLxfI",
	"containBox1": "containBox1_vXxT5A",
	"flexGrow1": "flexGrow1_22AMIL",
	"menuIcon": "menuIcon_c2MIqF"
};
export default ___CSS_LOADER_EXPORT___;
