let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let user = {
    add_user(params){
        return axios.post('/admin/user/add_user', params);
    },
    del_user(params){
        return axios.post('/admin/user/del_user', params);
    },
    update_user(params){
        return axios.post('/admin/user/update_user', params);
    },
    get_users(params){
        return axios.get('/admin/user/get_users', {params});
    },
}

module.exports = user;