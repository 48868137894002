import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    message, Modal,
    Row,
    Space,
    Table
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './pay_order.module.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    pay_orders: [],
    query: {
        page: 1,
        pageSize: 10,
    },//查询条件
    add_pay_order_data: {},//添加用户数据
    update_pay_order_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
});
/**
 * let PayOrderSchema = new Schema({
 *     price: {//订单金额
 *         type: Number,
 *         required: true,
 *     },
 *     type: {//user_subs_pay用户订阅的付款   user_pay用户直接支付
 *         type: String,
 *         default: 'user_subs_pay',
 *         required: true,
 *     },
 *     user: {
 *         type: Schema.Types.ObjectId, ref: 'user'
 *     },
 *     real_price: {//真实的入账金额
 *         type: Number,
 *     },
 *     status: {//支付订单状态 0未支付  1已支付
 *         type: Number,
 *         default: 0
 *     },
 *     notify_status: {//第三方订单回调状态  0失败  1成功
 *         type: Number,
 *         default: 0,
 *     },
 *     paysapi_id: {//系统订单号id
 *         type: String,
 *         index: true,
 *         unique: true,
 *         required: true,
 *     },
 *     order_id: String,//第三方通道订单号,
 *     rate: {//第三方通道费率
 *         type: Number,
 *         default: 0,
 *     },
 *     pay_url: String,//第三方支付跳转地址
 *     pay_api_name: String,//通道名字
 *     mark: String,//备注
 *     admin: {//执行操作的管理员
 *         type: Schema.Types.ObjectId, ref: 'admin'
 *     },
 *     display: {
 *         type: String,
 *         default: 'inline'
 *     },
 * }, {
 *     collection: 'pay_order',
 *     timestamps: {createdAt: 'ctime', updatedAt: 'etime'},
 * })
 */
let columns = [
    {
        title: '_id',
        dataIndex: '_id',
        key: '_id',
        width: 150,
        ellipsis: true,
    },

    {
        title: 'price',
        dataIndex: 'price',
        key: 'price',
        width: 150,
    },
    {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        width: 150,
    },
    {
        title: 'user_email',
        dataIndex: ['user','email'],
        key: 'user_email',
        width: 150,
    },
    {
        title: 'real_price',
        dataIndex: 'real_price',
        key: 'real_price',
        width: 150,
    },
    {
        title: 'status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
    },
    {
        title: 'notify_status',
        dataIndex: 'notify_status',
        key: 'notify_status',
        width: 150,
    },
    {
        title: 'paysapi_id',
        dataIndex: 'paysapi_id',
        key: 'paysapi_id',
        width: 150,
    },
    {
        title: 'order_id',
        dataIndex: 'order_id',
        key: 'order_id',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'rate',
        dataIndex: 'rate',
        key: 'rate',
        width: 150,
    },
    {
        title: 'pay_url',
        dataIndex: 'pay_url',
        key: 'pay_url',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'pay_api_name',
        dataIndex: 'pay_api_name',
        key: 'pay_api_name',
        width: 150,
    },
    {
        title: 'mark',
        dataIndex: 'mark',
        key: 'mark',
        width: 150,
    },
    {
        title: 'admin',
        dataIndex: 'admin',
        key: 'admin',
        width: 150,
    },
    {
        title: 'display',
        dataIndex: 'display',
        key: 'display',
        width: 150,
    },
    {
        title: 'ctime',
        dataIndex: 'ctime',
        key: 'ctime',
        valueType: 'dateTime',
        width: 160,
        render(ctime) {
            return moment(ctime).format('yyyy-MM-DD HH:mm')
        }
    },
    {
        title: 'etime',
        dataIndex: 'etime',
        key: 'etime',
        valueType: 'dateTime',
        width: 160,
        render(etime) {
            return moment(etime).format('yyyy-MM-DD HH:mm')   //1是男，2是女
        }
    },
    {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 200,
        render: (text, record) => (
            <Space size="middle">
                <Button type={'primary'} onClick={() => {
                    data.update_pay_order_data = record;
                    data.is_show_update_dialog = true;
                }}>edit</Button>
                <Button type={'danger'} onClick={() => {
                    Modal.confirm({
                        content: 'are you sure delete this record',
                        onOk: function () {
                            delData(record)
                        },
                    })
                }}>Delete</Button>
            </Space>
        ),
    },
]

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/pay_order?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.pay_order.get_pay_orders(data.query);
    if (res.code != 0) return;
    data.pay_orders = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    await getList(data.query.page, data.query.pageSize);
}

async function updateData(newData) {
    data.update_pay_order_data = newData;
    let res = await api.pay_order.update_pay_order(data.update_pay_order_data);
    if (res.code != 0) return;
    message.success('update pay_order success');
    data.is_show_update_dialog = false;
}

async function addData(newData) {
    data.add_pay_order_data = newData;
    let res = await api.pay_order.add_pay_order(data.add_pay_order_data);
    if (res.code != 0) return;
    message.success('add pay_order success');
    data.is_show_add_dialog = false;
    getList();
}

async function delData(data) {
    let res = await api.pay_order.del_pay_order({_id: data._id});
    if (res.code != 0) return;
    message.success('del pay_order success');
    getList();
}

// create observer
let PayOrder = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    if (commonUtils.isEmpty(data.pay_orders)) return <div/>;
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.pay_orders)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }}>add new</Button>
            </Col>
            <Table
                rowKey="_id"
                columns={columns}
                dataSource={data.pay_orders}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true, showSizeChanger: true, defaultCurrent: 1,
                    total: data.total, onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_pay_order_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            <AddUpdateDataDialog dataSource={data.update_pay_order_data}
                                 columns={columns}
                                 is_show_dialog={data.is_show_update_dialog}
                                 onSure={(newData) => {
                                     updateData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_pay_order_data = {};
                                 }}
            />
        </div>
    );
})
export default PayOrder;
