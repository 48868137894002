let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let interpreter_work_status_statistics = {
    add_interpreter_work_status_statistics(params){
        return axios.post('/admin/interpreter_work_status_statistics/add_interpreter_work_status_statistics', params);
    },
    del_interpreter_work_status_statistics(params){
        return axios.post('/admin/interpreter_work_status_statistics/del_interpreter_work_status_statistics', params);
    },
    update_interpreter_work_status_statistics(params){
        return axios.post('/admin/interpreter_work_status_statistics/update_interpreter_work_status_statistics', params);
    },
    get_interpreter_work_status_statisticss(params){
        return axios.get('/admin/interpreter_work_status_statistics/get_interpreter_work_status_statisticss', {params});
    },
}

module.exports = interpreter_work_status_statistics;
