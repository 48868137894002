import React, {useContext, createContext, useState} from "react";
import {Breadcrumb, Button, Col, Divider, Image, Layout, Menu, Row} from 'antd';
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import {Content, Header} from "antd/lib/layout/layout";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import {observer} from "mobx-react";

import PrivateRoute from "./private_route";
import Home from "../pages/home/home";
import HomeLogin from "../pages/home_login/home_login";
import User from "../pages/user/user";
import Admin from "../pages/admin/admin";
import Interpreter from "../pages/interpreter/interpreter";
import Order from "../pages/order/order";
import CashOrder from "../pages/cash_order/cash_order";
import SystemConfig from "../pages/system_config/system_config";
import InterpreterWorkStatusStatistics
    from "../pages/interpreter_work_status_statistics/interpreter_work_status_statistics";
import UserAdvanceNotice from "../pages/user_advance_notice/user_advance_notice";
import MoneyCutOf from "../pages/money_cut_of/money_cut_of";
import PayOrder from "../pages/pay_order/pay_order";
import RegisterEventInfo from "../pages/register_event_info/register_event_info";
import PageIp from "../pages/page_ip/page_ip";

let store = require('../store');
let api = require('src/api');
let history;
let currentPath = '';
//侧边菜单配置
let defaultRouter = {
    route: {
        path: '/',
        routes: [
            //{
            //    path: '/home_login',
            //    name: 'welcome',
            //    component: HomeLogin,
            //},
            {
                path: '/user',
                name: 'User Management',
                routes: [
                    {
                        path: '/home_login/user',
                        name: 'User Management',
                        component: User,
                    },
                    {
                        path: '/home_login/user_advance_notice',
                        name: 'User Advance Notice Management',
                        component: UserAdvanceNotice,
                    },
                    {
                        path: '/home_login/register_event_info',
                        name: 'Register Event Info Management',
                        component: RegisterEventInfo,
                    },
                    {
                        path: '/home_login/page_ip',
                        name: 'Page IP Management',
                        component: PageIp,
                    }
                ]
            },
            {
                path: '/interpreter',
                name: 'Interpreter Management',
                routes: [
                    {
                        path: '/home_login/interpreter',
                        name: 'Interpreter Management',
                        component: Interpreter,
                    }
                ]
            },
            {
                path: '/admin',
                name: 'Admin Management',
                routes: [
                    {
                        path: '/home_login/admin',
                        name: 'Admin Management',
                        component: Admin,
                    }
                ]
            },
            {
                path: '/order',
                name: 'Order Management',
                routes: [
                    {
                        path: '/home_login/order',
                        name: 'Order Management',
                        component: Order,
                    }
                ]
            },
            {
                path: '/cash_order',
                name: 'Billing Management',
                routes: [
                    {
                        path: '/home_login/cash_order',
                        name: 'Billing Management',
                        component: CashOrder,
                    },
                    // {
                    //     path: '/home_login/pay_order',
                    //     name: 'User Payment Management',
                    //     component: PayOrder,
                    // }
                ]
            },
            {
                path: '/system_config',
                name: 'System Management',
                routes: [
                    {
                        path: '/home_login/system_config',
                        name: 'System Management',
                        component: SystemConfig,
                    }
                ]
            },
            {
                path: '/interpreter_work_status_statistics',
                name: 'Interpreter Work Status Statistics',
                routes: [
                    {
                        path: '/home_login/interpreter_work_status_statistics',
                        name: 'Interpreter Work Status Statistics',
                        component: InterpreterWorkStatusStatistics,
                    }
                ]
            },
            {
                path: '/money_cut_of',
                name: 'Statements',
                routes: [
                    {
                        path: '/home_login/money_cut_of',
                        name: 'Statements',
                        component: MoneyCutOf,
                    }
                ]
            },
            {
                name: 'Log out',
                action: 'logout',
            }
        ],
    },
    location: {
        pathname: '/',
    },
};

//获取所有父级菜单的keys
function getAllParentMenuKeys(routes) {
    let keys = [];
    routes.forEach(route => {
        if (route.routes && route.routes.length > 0) {
            const key = route.path.split('/').filter(Boolean)[0];
            if (key) {
                keys.push(`/${key}`);
            }
            keys = [...keys, ...getAllParentMenuKeys(route.routes)];
        }
    });
    return keys;
}

//获取侧边菜单
function getSiderMenu(routes) {
    let views = [];
    currentPath = window.location.pathname.split('/').pop();
    for (let route of routes) {
        let subRoutes = route.routes;
        let subMenus = [];
        if (subRoutes) {
            for (let subRoute of subRoutes) {
                let subMenu = (
                    <Menu.Item key={subRoute.path}>
                        <Link to={subRoute.path}>{subRoute.name}</Link>
                    </Menu.Item>
                );
                subMenus.push(subMenu);
            }
        }
        // 添加了logout按钮
        if (route.action === 'logout') {
            views.push(
                <Menu.Item key="logout" onClick={logout}>
                    {route.name}
                </Menu.Item>
            );
            continue;
        }
        let view;
        if (!subRoutes) {
            view = (
                <Menu.Item key={route.path} title={route.name}>
                    <Link to={route.path}>{route.name}</Link>
                </Menu.Item>
            );
        } else {
            view = (
                <SubMenu key={route.path} title={route.name}>
                    {subMenus}
                </SubMenu>
            );
        }
        views.push(view);
    }
    return views;
}

function getMenuRoute() {
    let routes = [];
    for (let route of defaultRouter.route.routes) {
        let subRoutes = route.routes;
        if (subRoutes) {
            for (let subRoute of subRoutes) {
                let routeItem = (
                    <PrivateRoute path={subRoute.path} component={subRoute.component}/>
                );
                routes.push(routeItem);
            }
        } else {
            let routeItem = (
                <PrivateRoute path={route.path} component={route.component}/>
            );
            routes.push(routeItem);
        }
    }
    return routes;
}


async function getInterpreterLanguages() {
    let res = await api.interpret_lang.get_interpret_langs({pageSize: 1000});
    if (res.code != 0) return;
    let arr = res.data.item;
    for (let item of arr) {
        item.title = item.language_name;
    }
    store.persist.interpret_langs = arr;
}

getInterpreterLanguages();


function logout() {
    store.persist.token = null;
    store.persist.userInfo = {};
    window.location.href = '/';
    // history.push('/')
}


let MyRoute = observer(function () {
    history = useHistory();
    // location = useLocation();
    // console.log('location', location);
    let route =
        <Router>
            <div className='appContainerAdmin'>
                <Switch>
                    <PrivateRoute path="/home">
                        <Home/>
                    </PrivateRoute>


                    {/*登录后的权限页面*/}
                    <PrivateRoute path="/home_login">
                        <Layout style={{height: '100vh'}}>
                            {/*
                            <Header className="header">
                                <div className="logo" />
                                <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
                                <Menu.Item key="1" onClick={logout}>Log out</Menu.Item>
                                </Menu>
                            </Header>
                            */}
                            <Layout>
                                <Sider width={200} className="site-layout-background">
                                    <Menu
                                        mode="inline"
                                        defaultSelectedKeys={['1']}
                                        // defaultOpenKeys={[`/${currentPath}`]}
                                        defaultOpenKeys={getAllParentMenuKeys(defaultRouter.route.routes)}
                                        style={{height: '100%', borderRight: 0}}>
                                        {getSiderMenu(defaultRouter.route.routes)}
                                    </Menu>
                                </Sider>
                                <Layout style={{padding: '0 24px 24px'}}>
                                    <Breadcrumb style={{margin: '16px 0'}}>
                                        {/*<Breadcrumb.Item>Home</Breadcrumb.Item>*/}
                                        {/*<Breadcrumb.Item>List</Breadcrumb.Item>*/}
                                        {/*<Breadcrumb.Item>App</Breadcrumb.Item>*/}
                                    </Breadcrumb>
                                    <Content
                                        className="site-layout-background"
                                        style={{
                                            padding: 24,
                                            margin: 0,
                                            minHeight: 280,
                                        }}>
                                        {/*<PrivateRoute path="/home_login/user"><User/></PrivateRoute>*/}
                                        {/*<Route path="/home_login/user" component={User}/>*/}
                                        {getMenuRoute()}
                                    </Content>
                                </Layout>
                            </Layout>
                        </Layout>
                    </PrivateRoute>


                    <PrivateRoute path="/">
                        <Home/>
                    </PrivateRoute>
                    <Route render={() => <Redirect to="/"/>}/>
                </Switch>
            </div>
        </Router>;
    return route;
})

export default MyRoute;
