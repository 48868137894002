let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let page_ip = {
    add_page_ip(params){
        return axios.post('/admin/page_ip/add_page_ip', params);
    },
    del_page_ip(params){
        return axios.post('/admin/page_ip/del_page_ip', params);
    },
    update_page_ip(params){
        return axios.post('/admin/page_ip/update_page_ip', params);
    },
    get_page_ips(params){
        return axios.get('/admin/page_ip/get_page_ips', {params});
    },
}

module.exports = page_ip;
