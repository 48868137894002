let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let pay_order = {
    add_pay_order(params){
        return axios.post('/admin/pay_order/add_pay_order', params);
    },
    del_pay_order(params){
        return axios.post('/admin/pay_order/del_pay_order', params);
    },
    update_pay_order(params){
        return axios.post('/admin/pay_order/update_pay_order', params);
    },
    get_pay_orders(params){
        return axios.get('/admin/pay_order/get_pay_orders', {params});
    },
}

module.exports = pay_order;
