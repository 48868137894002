let user = require('./user');
let interpreter = require('./interpreter');
let order = require('./order');
let admin = require('./admin');

let api = {
    user,
    interpreter,
    order,
    admin,
    cash_order: require('./cash_order'),
    system_config: require('./system_config'),
    interpret_lang: require('./interpret_lang'),
    cert: require('./cert'),
    interpreter_work_status_statistics: require('./interpreter_work_status_statistics'),
    user_advance_notice: require('./user_advance_notice'),
    money_cut_of: require('./money_cut_of'),
    pay_order: require('./pay_order'),
    register_event_info: require('./register_event_info'),
    page_ip: require('./page_ip'),
}

module.exports = api;
