import React from 'react';
import {Route, Redirect, withRouter, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import HomeLogin from "../pages/home_login/home_login";

let history;


const PrivateRoute = (props) => {
    history = useHistory();
    let store = require('../store');
    let isLogin = !!store.persist.token;
    let children = props.children;
    let component = props.component;

    let path = props.path;
    //访问的是否是不用授权的页面
    let isUnAuthPage = ['/login/:roleType', '/register', '/'].includes(path);
    if (isLogin) {
        if (isUnAuthPage) return (
            <Route render={() =>
                <Redirect to={'/home_login'}/>
            }/>
        );
        return (
            <Route {...props} component={component}>{children}</Route>
        )
    } else {
        if (isUnAuthPage) return (
            <Route {...props} component={component}>{children}</Route>
        )
        return (
            <Route render={() =>
                <Redirect to={'/'}/>
            }/>
        )
    }
}

export default PrivateRoute;