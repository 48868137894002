let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let user_advance_notice = {
    add_user_advance_notice(params){
        return axios.post('/admin/user_advance_notice/add_user_advance_notice', params);
    },
    del_user_advance_notice(params){
        return axios.post('/admin/user_advance_notice/del_user_advance_notice', params);
    },
    update_user_advance_notice(params){
        return axios.post('/admin/user_advance_notice/update_user_advance_notice', params);
    },
    get_user_advance_notices(params){
        return axios.get('/admin/user_advance_notice/get_user_advance_notices', {params});
    },
}

module.exports = user_advance_notice;
