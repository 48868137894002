let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;
let store = require('src/store');

let money_cut_of = {
    add_money_cut_of(params){
        return axios.post('/admin/money_cut_of/add_money_cut_of', params);
    },
    del_money_cut_of(params){
        return axios.post('/admin/money_cut_of/del_money_cut_of', params);
    },
    update_money_cut_of(params){
        return axios.post('/admin/money_cut_of/update_money_cut_of', params);
    },
    get_money_cut_ofs(params){
        return axios.get('/admin/money_cut_of/get_money_cut_ofs', {params});
    },
}

module.exports = money_cut_of;
