let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let interpret_lang = {
    add_interpret_lang(params){
        return axios.post('/admin/interpret_lang/add_interpret_lang', params);
    },
    del_interpret_lang(params){
        return axios.post('/admin/interpret_lang/del_interpret_lang', params);
    },
    update_interpret_lang(params){
        return axios.post('/admin/interpret_lang/update_interpret_lang', params);
    },
    get_interpret_langs(params){
        return axios.get('/admin/interpret_lang/get_interpret_langs', {params});
    },
}

module.exports = interpret_lang;
