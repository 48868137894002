let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let order = {
    //获取订单列表
    get_order_list(params) {
        return axios.get('/webapp/order/get_order_list', {params: params});
    },
    //获取口译语言列表
    get_interpret_lang_list(params) {
        return axios.get('/webapp/interpret_lang/get_interpret_lang_list', {params: params});
    },
    //获取证书列表
    get_cert_list(params) {
        return axios.get('/webapp/cert/get_cert_list', {params: params});
    },
    //获取支持服务列表
    get_support_service_type_list(params) {
        return axios.get('/webapp/support_service_type/get_support_service_type_list', {params: params});
    },
    //添加订单
    add_order(params) {
        return axios.post('/webapp/order/add_order', params);
    },
    //更改订单
    update_order(params) {
        return axios.post('/webapp/order/update_order', params);
    },
    //获取证书列表
    get_interpret_usable_time(params) {
        return axios.get('/webapp/order/get_interpret_usable_time', {params: params});
    },
    //获取联系方式列表
    get_contact_list(params) {
        return axios.get('/webapp/contact/get_contact_list', {params: params});
    },
    //添加联系方式
    add_contact(params) {
        return axios.post('/webapp/contact/add_contact', params);
    },
    //删除联系方式
    del_contact(params) {
        return axios.post('/webapp/contact/del_contact', params);
    },
    //分配订单口译师
    distribute_interpreter(params) {
        return axios.post('/webapp/order/distribute_interpreter', params);
    },
    //分配订单口译师 必须要有_id, role_type, msg；msg可以是字符串或者对象
    send_msg_to(params) {
        return axios.post('/webapp/order/send_msg_to', params);
    },
    //订单评价接口
    add_order_evaluate(params) {
        return axios.post('/webapp/order/add_order_evaluate', params);
    },
    //获取订单评价接口
    get_order_evaluate(params) {
        return axios.get('/webapp/order/get_order_evaluate', {params});
    },
    //获取分配口译师时的状态数据
    get_distribute_interpreter_status(params) {
        return axios.get('/webapp/order/get_distribute_interpreter_status', {params: params});
    },
    //获取用户匹配口译师的等待次数
    get_user_distribute_intp_wait_times(params) {
        return axios.get('/webapp/order/get_user_distribute_intp_wait_times', {params: params});
    },
    //发起agora通话
    start_agora_call(params) {
        return axios.post('/webapp/order/start_agora_call', params);
    },
    //拒绝agora通话
    refuse_agora_call(params) {
        return axios.post('/webapp/order/refuse_agora_call', params);
    },
    //自己取消agora通话
    self_cancel_agora_call(params) {
        return axios.post('/webapp/order/self_cancel_agora_call', params);
    }
}

module.exports = order;
