let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let interpreter = {
    add_interpreter(params){
        return axios.post('/admin/interpreter/add_interpreter', params);
    },
    del_interpreter(params){
        return axios.post('/admin/interpreter/del_interpreter', params);
    },
    update_interpreter(params){
        return axios.post('/admin/interpreter/update_interpreter', params);
    },
    get_interpreters(params){
        return axios.get('/admin/interpreter/get_interpreters', {params});
    },
}

module.exports = interpreter;
