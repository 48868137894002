import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    message, Modal,
    Row,
    Space,
    Table
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './system_config.module.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    system_configs: [],
    query: {
        page: 1,
        pageSize: 10,
    },//查询条件
    add_system_config_data: {},//添加用户数据
    update_system_config_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
});

let columns = [
    {
        title: '_id',
        dataIndex: '_id',
        key: '_id',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: 160,
    },
    {
        title: 'value',
        dataIndex: 'value',
        key: 'value',
        width: 160,
    },
    {
        title: 'ctime',
        dataIndex: 'ctime',
        key: 'ctime',
        valueType: 'dateTime',
        width: 160,
        render(ctime) {
            return moment(ctime).format('yyyy-MM-DD HH:mm')
        }
    },
    {
        title: 'etime',
        dataIndex: 'etime',
        key: 'etime',
        valueType: 'dateTime',
        width: 160,
        render(etime) {
            return moment(etime).format('yyyy-MM-DD HH:mm')   //1是男，2是女
        }
    },
    {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 200,
        render: (text, record) => (
            <Space size="middle">
                {/*<Button type={'primary'} onClick={() => {*/}
                {/*    data.update_system_config_data = record;*/}
                {/*    data.is_show_update_dialog = true;*/}
                {/*}}>edit</Button>*/}
                {/*<Button type={'danger'} onClick={() => {*/}
                {/*    Modal.confirm({*/}
                {/*        content: 'are you sure delete this record',*/}
                {/*        onOk: function () {*/}
                {/*            delData(record)*/}
                {/*        },*/}
                {/*    })*/}
                {/*}}>Delete</Button>*/}
            </Space>
        ),
    },
]

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/system_config?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.system_config.get_system_configs(data.query);
    if (res.code != 0) return;
    data.system_configs = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    await getList(data.query.page, data.query.pageSize);
}

async function updateData(newData) {
    data.update_system_config_data = newData;
    let res = await api.system_config.update_system_config(data.update_system_config_data);
    if (res.code != 0) return;
    message.success('update system_config success');
    data.is_show_update_dialog = false;
}

async function addData(newData) {
    data.add_system_config_data = newData;
    let res = await api.system_config.add_system_config(data.add_system_config_data);
    if (res.code != 0) return;
    message.success('add system_config success');
    data.is_show_add_dialog = false;
    getList();
}

async function delData(data) {
    let res = await api.system_config.del_system_config({_id: data._id});
    if (res.code != 0) return;
    message.success('del system_config success');
    getList();
}

// create observer
let SystemConfig = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    if (commonUtils.isEmpty(data.system_configs)) return <div/>;
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.system_configs)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }}>add new</Button>
            </Col>
            <Table
                rowKey="_id"
                columns={columns}
                dataSource={data.system_configs}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true, showSizeChanger: true, defaultCurrent: 1,
                    total: data.total, onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_system_config_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            <AddUpdateDataDialog dataSource={data.update_system_config_data}
                                 columns={columns}
                                 is_show_dialog={data.is_show_update_dialog}
                                 onSure={(newData) => {
                                     updateData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_system_config_data = {};
                                 }}
            />
        </div>
    );
})
export default SystemConfig;
