import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    DatePicker,
    message, Modal,
    Row,
    Space,
    Table
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './register_event_info.module.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment-timezone');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    register_event_infos: [],
    query: {
        page: 1,
        pageSize: 100,
    },//查询条件
    add_register_event_info_data: {},//添加用户数据
    update_register_event_info_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
});

let columns = [
    // {
    //     title: '_id',
    //     dataIndex: '_id',
    //     key: '_id',
    //     width: 150,
    //     ellipsis: true,
    // },
    /**
     * email: {
     *         type: String,
     *         default: '',
     *         index: true,
     *         unique: true,
     *     },
     *     email_code: {
     *         type: String,
     *         default: '',
     *     },
     *     email_code_sent: {
     *         type: Boolean,
     *         default: false,
     *     },
     *     is_login_success: {
     *         type: Boolean,
     *         default: false,
     *     }
     */
    {
        title: 'email',
        dataIndex: 'email',
        key: 'email',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'email_code',
        dataIndex: 'email_code',
        key: 'email_code',
        width: 150,
        ellipsis: true,
    },
    {
        title: 'email_code_sent',
        dataIndex: 'email_code_sent',
        key: 'email_code_sent',
        width: 150,
        ellipsis: true,
        render: (text, record) => (record.email_code_sent ? "Y" : "N")
    },
    {
        title: 'is_login_success',
        dataIndex: 'is_login_success',
        key: 'is_login_success',
        width: 150,
        ellipsis: true,
        render: (text, record) => (record.is_login_success ? "Y" : "N")
    }
    ,
    {
        title: 'ctime',
        dataIndex: 'ctime',
        key: 'ctime',
        width: 160,
        render(ctime) {
            return moment(ctime).format('yyyy-MM-DD HH:mm')
        }
    },
    {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 180,
        render: (text, record) => (
            <Space size="middle">
                <Button type={'primary'} onClick={() => {
                    data.update_register_event_info_data = record;
                    data.is_show_update_dialog = true;
                }}>Edit</Button>
                <Button type={'danger'} onClick={() => {
                    Modal.confirm({
                        content: 'are you sure delete this record',
                        onOk: function () {
                            delUser(record)
                        },
                    })
                }}>Delete</Button>
            </Space>
        ),
    },
]

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history,'/home_login/register_event_info?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.register_event_info.get_register_event_infos(data.query);
    if (res.code != 0) return;
    data.register_event_infos = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    await getList(data.query.page, data.query.pageSize);
}

async function updateUser(newData) {
    data.update_register_event_info_data = newData;
    let res = await api.register_event_info.update_register_event_info(data.update_register_event_info_data);
    if (res.code != 0) return;
    message.success('update register_event_info success');
    data.is_show_update_dialog = false;
    history.go(0);
}

async function addUser(newData) {
    data.add_register_event_info_data = newData;
    let res = await api.register_event_info.add_register_event_info(data.add_register_event_info_data);
    if (res.code != 0) return;
    message.success('add register_event_info success');
    data.is_show_add_dialog = false;
    getList();
}

async function delUser(data) {
    let res = await api.register_event_info.del_register_event_info({_id: data._id});
    if (res.code != 0) return;
    message.success('del register_event_info success');
    getList();
}

// create observer
let RegisterEventInfo = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.register_event_infos)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }} style={{marginBottom: "10px"}}>add new</Button>
            </Col>
            <Table
                // style={{display:'inline'}}
                rowKey="_id"
                columns={columns}
                dataSource={data.register_event_infos}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addUser(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_register_event_info_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            <AddUpdateDataDialog dataSource={data.update_register_event_info_data}
                                 columns={columns}
                                 is_show_dialog={data.is_show_update_dialog}
                                 onSure={(newData) => {
                                     updateUser(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_register_event_info_data = {};
                                 }} />
        </div>
    );
})
export default RegisterEventInfo;
