import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    message, Modal,
    Row,
    Space, Spin,
    Table
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './money_cut_of.module.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    money_cut_ofs: [],
    query: {
        page: 1,
        pageSize: 100,
    },//查询条件
    add_money_cut_of_data: {},//添加用户数据
    update_money_cut_of_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
    is_loading: false,//是否正在加载数据
});

let columns;

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/money_cut_of?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    data.is_loading = true;
    let res = await api.money_cut_of.get_money_cut_ofs(data.query);
    data.is_loading = false;
    if (res.code != 0) return;
    data.money_cut_ofs = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    columns = [
        // {
        //     title: '_id',
        //     dataIndex: '_id',
        //     key: '_id',
        //     width: 150,
        //     ellipsis: true,
        // },
        /**
         * money_before: { // 截断前的金额
         *         type: Number,
         *         default: 0,
         *     },
         *     money_after: { // 截断后的金额
         *         type: Number,
         *         default: 0,
         *     },
         *     display: {
         *         type: String,
         *         default: 'inline',
         *     },
         *     user: {type: Schema.Types.ObjectId, ref: 'user'},
         *     interpreter: {type: Schema.Types.ObjectId, ref: 'interpreter'},
         */
        //需要有ctime,user.email,interpreters的id2和second_name 还有content
        {
            title: 'user/owner',
            dataIndex: ['user', 'email'],
            key: 'user.email',
            ellipsis: true,
        },
        {
            title: 'interpreter',
            dataIndex: ['interpreter', 'id2'],
            key: 'interpreter.id2',
        },
        {
            title: 'user_id2',
            dataIndex: ['user', 'id2'],
            key: 'user.id2',
        },
        {
            title: 'invoice_receivers',
            dataIndex: ['user', 'invoice_receivers'],
            key: 'user.invoice_receivers',
            ellipsis: true,
            render(invoice_receivers) {
                if (commonUtils.isEmpty(invoice_receivers)) return '';
                let desc = '';
                for (let invoice_receiver of invoice_receivers) {
                    //有invoice_receiver.email和cc_email1和cc_email2
                    desc += `email:${invoice_receiver.email}`;
                    if (!commonUtils.isEmpty(invoice_receiver.cc_email1)) {
                        desc += `\rcc_email1:${invoice_receiver?.cc_email1}`;
                    }
                    if (!commonUtils.isEmpty(invoice_receiver.cc_email2)) {
                        desc += `\rcc_email2:${invoice_receiver?.cc_email2}`;
                    }
                }
                return desc;
            }
        },
        {
            title: 'money_before',
            dataIndex: 'money_before',
            key: 'money_before',
        },
        {
            title: 'money_after',
            dataIndex: 'money_after',
            key: 'money_after',
        },
        {
            title: 'paid_status',
            dataIndex: 'paid_status',
            key: 'paid_status',
            valueEnum: ['unpaid', 'paid', 'tbd'],
            valueType: 'select',
        },
        {
            title: 'ctime',
            dataIndex: 'ctime',
            key: 'ctime',
            width: 160,
            render(ctime) {
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 300,
            render: (text, record) => (
                <Space size="middle">
                    <Button type={'primary'} onClick={() => {
                        data.update_money_cut_of_data = record;
                        console.log('data.update_money_cut_of_data', JSON.parse(JSON.stringify(data.update_money_cut_of_data)));
                        data.is_show_update_dialog = true;
                    }}>edit</Button>
                    {
                        !commonUtils.isEmpty(record.user) ? (
                            <Button type={'primary'} onClick={() => {
                                let url = config.url.user_client_url + `/login/user?admin_token=${store.persist.token}&user_name=${record.user.user_name}`;
                                window.open(url, '_blank');
                            }}>to user page</Button>
                        ) : (
                            <Button type={'primary'} onClick={() => {
                                let url = config.url.interpreter_client_url + `/login/user?admin_token=${store.persist.token}&user_name=${record.interpreter.user_name}`;
                                window.open(url, '_blank');
                            }}>to intp page</Button>
                        )
                    }

                    <Button type={'danger'} onClick={() => {
                        Modal.confirm({
                            content: 'are you sure delete this record',
                            onOk: function () {
                                delUser(record)
                            },
                        })
                    }}>Delete</Button>
                </Space>
            ),
        },
    ];
    await getList(data.query.page, data.query.pageSize);
}

async function updateUser(newData) {
    data.update_money_cut_of_data = newData;
    let res = await api.money_cut_of.update_money_cut_of(data.update_money_cut_of_data);
    if (res.code != 0) return;
    message.success('update money_cut_of success');
    data.is_show_update_dialog = false;
    history.go(0);
}

async function addUser(newData) {
    data.add_money_cut_of_data = newData;
    let res = await api.money_cut_of.add_money_cut_of(data.add_money_cut_of_data);
    if (res.code != 0) return;
    message.success('add money_cut_of success');
    data.is_show_add_dialog = false;
    getList();
}

async function delUser(data) {
    let res = await api.money_cut_of.del_money_cut_of({_id: data._id});
    if (res.code != 0) return;
    message.success('del money_cut_of success');
    getList();
}

// create observer
let MoneyCutOf = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    if (commonUtils.isEmpty(data.money_cut_ofs)) return (
        <div>
            {data.is_loading ? <Spin/> : null}
        </div>
    );
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.money_cut_ofs)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }}>add new</Button>
            </Col>
            <Table
                // style={{display:'inline'}}
                rowKey="_id"
                columns={columns}
                dataSource={data.money_cut_ofs}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addUser(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_money_cut_of_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            {
                data.is_show_update_dialog ? (
                    <AddUpdateDataDialog dataSource={data.update_money_cut_of_data}
                                         columns={columns}
                                         onSure={(newData) => {
                                             updateUser(newData);
                                         }}
                                         onCancel={() => {
                                             data.is_show_update_dialog = false;
                                             data.update_money_cut_of_data = {};
                                             console.log('data.update_money_cut_of_data2', JSON.parse(JSON.stringify(data.update_money_cut_of_data)));
                                         }}
                    />
                ) : null
            }

        </div>
    );
})
export default MoneyCutOf;
