let config = require('commonProject/config/config');
let axios = require('src/utils/request').default;

let cert = {
    add_cert(params){
        return axios.post('/admin/cert/add_cert', params);
    },
    del_cert(params){
        return axios.post('/admin/cert/del_cert', params);
    },
    update_cert(params){
        return axios.post('/admin/cert/update_cert', params);
    },
    get_certs(params){
        return axios.get('/admin/cert/get_certs', {params});
    },
}

module.exports = cert;
