// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerContainBox1_1zPvAJ .containBox1_2-UI8q {\n  font-size: 20px;\n}\n.headerContainBox1_1zPvAJ .flexGrow1_LwTyzp {\n  flex-grow: 1;\n}\n.headerContainBox1_1zPvAJ .menuIcon_2b8p3h {\n  width: 3vw;\n  height: 3vw;\n}\n", "",{"version":3,"sources":["webpack://src/component/valueTypeView/valueTypeView.module.less"],"names":[],"mappings":"AAAA;EAGI,eAAA;AADJ;AAFA;EAQI,YAAA;AAHJ;AALA;EAYI,UAAA;EACA,WAAA;AAJJ","sourcesContent":[".headerContainBox1 {\n\n  .containBox1{\n    font-size: 20px;\n    //background-color: green;\n  }\n\n  .flexGrow1 {\n    flex-grow: 1;\n  }\n\n  .menuIcon{\n    width: 3vw;\n    height: 3vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainBox1": "headerContainBox1_1zPvAJ",
	"containBox1": "containBox1_2-UI8q",
	"flexGrow1": "flexGrow1_LwTyzp",
	"menuIcon": "menuIcon_2b8p3h"
};
export default ___CSS_LOADER_EXPORT___;
